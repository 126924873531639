#modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  overflow-y: auto;
  transform: translateZ(0);
  background-color: rgba(0, 0, 0, 0.56);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  > .content {
    border-radius: 8px;
    padding: 0 16px 16px 16px;
    display: flex;
    flex-direction: column;
    max-height: 95vh;
    overflow-y: auto;
    background-color: white;

    > .close {
      align-self: flex-end;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      padding: 5px 0 5px 0;
    }
  }
}
