#chainDetail {
  // max-width: 686px;
  width: 100%;
  flex: 1;

  > .wrapper {
    background: #ffffff;
    border: 2px solid #dee1e3;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding-bottom: 64px;
  }

  .chainHeader {
    height: 80px;
    display: flex;
    align-items: center;
    padding-left: 14px;
    margin-bottom: 14px;
    border-bottom: 2px solid #dee1e3;
    > div {
      margin-left: 14px;
      display: flex;
      flex-direction: column;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #3c3b3b;
      > span {
        margin-bottom: 4px;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        color: #000000;
      }
    }
  }

  .chainSeeMore {
    height: 36px;
    margin-left: 47px;
    margin-top: -12px;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #7a48ff;
    cursor: pointer;
    z-index: 100;
    > svg {
      width: 15px;
      height: 15px;
      margin-right: 6px;
      stroke: '#7A48FF';
    }
  }
  .documentationSeeMore {
    margin-left: 0;
    margin-top: 0;
    display: flex;
    align-items: center;
    font-weight: 400 !important;
    font-size: 10px !important;
    line-height: 17px;
    color: rgb(120, 165, 255) !important;
    cursor: pointer;
    z-index: 100;
    > svg {
      width: 15px;
      height: 15px;
      margin-left: 6px;
      stroke: 'red';
    }
  }
  .linkUbication {
    color: #7a48ff;
    font-weight: 600;
    text-decoration: none;
    font-size: 12px;
  }
  .chainBody {
    padding: 0 14px;
    position: relative;

    > .chainBodyLine {
      position: absolute;
      left: 37px;
      top: 0;
      bottom: 0;
      border-left: 2px solid #00e697;
    }

    > .chainBodyConnect {
      position: absolute;
      left: 37px;
      top: -68px;
      right: 0;
    }
  }

  .chainRow {
    display: flex;
    flex-direction: row;
    padding-bottom: 18px;
    position: relative;

    > .chainRowIcon {
      height: 48px;
      width: 48px;
      border-radius: 8px;
      align-items: center;
      justify-content: center;
      color: #241200;
      display: flex;
      z-index: 100;
      font-weight: bold;
    }
    > .chainRowLine {
      position: absolute;
      left: 23px;
      top: 0;
      bottom: 0;
      border-left: 2px solid #00e697;
    }
    > .chainRowLineDashed {
      border-left: 2px dashed #c3beb6;
    }

    > .chainText {
      margin-left: 6px;
      flex: 1;
      flex-direction: column;
      > div {
        margin-top: 14px;
        margin-bottom: 2px;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #241200;
      }
      > a {
        color: #7a48ff;
        font-weight: 600;
        text-decoration: none;
        font-size: 12px;
      }
      > span {
        margin-top: 6px;
        display: block;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #3c3b3b;
      }
      > .pictures {
        margin-top: 5px;
        > label {
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #777777;
        }
        > div {
          margin-top: 4px;
          display: flex;
          flex-direction: row;
          gap: 6px;
          flex-wrap: wrap;
          > img {
            width: 64px;
            height: 64px;
            object-fit: cover;
            background-color: #c4c4c4;
            border-radius: 8px;
            overflow: hidden;
            cursor: pointer;
          }
          > .boxPdf {
            width: 64px;
            height: 64px;
            > button {
              width: 100%;
              border: none;
              padding: 0;
              background-color: transparent;
            }
            > button > a {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: flex-end;
              color: #777777;
              text-decoration: none;
            }

            > button > a > svg {
              width: 64px;
              height: 64px;
              object-fit: cover;
              border-radius: 8px;
              overflow: hidden;
              cursor: pointer;
            }
            > button > a > label {
              font-weight: 400;
              font-size: 9px;
              line-height: 14px;
              color: #777777;
              padding-top: 4px;
            }
          }
        }
      }
    }
  }
}
