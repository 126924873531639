#header {
  position: absolute;
  width: 100%;
  height: auto;
  padding: 0 16px;
  left: 0px;
  top: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #001921;
  color: white;
  .iconLogo {
    height: 40px;
    width: auto;
    margin: 15px 0 10px 0;
    @media (max-width: 900px) {
      height: 40px;
    }
    @media (max-width: 600px) {
      height: 30px;
    }
  }
  .nav_language {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .button_language {
    background: transparent;
    text-transform: uppercase;
    color: white;
    border: none;
    display: flex;
    align-items: center;
  }
  .button_language span {
    padding-left: 10px;
    text-transform: uppercase;
  }
  .nav_nav_link {
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    color: black;
    cursor: pointer !important;
    padding: 1% 1%;
    align-items: center;
    display: flex;
    text-align: center;
    text-decoration: none;
  }
  .nav_nav_link:hover {
    color: #5330c9;
  }
  .nav_nav_link_active {
    color: #5330c9;
    border-radius: 4px;
  }
  .iconWorld {
    height: 25px;
    width: auto;
    margin-right: 0.5rem;
  }
  .iconSelect {
    height: 10px;
    width: auto;
    margin-left: 6px;
  }
  // DESPLEGABLE IDIOMAS
  .nav_MenuLanguage {
    position: absolute;
    top: 80px;
    right: 35px;
    z-index: 2;
    width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 1%;
    background-color: white;
  }
  .button_languageOption {
    width: 100%;
    text-align: left;
    border-radius: 4px;
    padding: 6% 5%;
    margin: 1%;
    background-color: transparent;
    border: none;
  }
  .button_languageOption_active {
    border: 1px solid #ab84ff;
    color: #ab84ff;
  }
  @media (max-width: 900px) {
    padding-right: 32px;
  }
  @media (max-width: 600px) {
    height: 65px;
    padding-right: 16px;
  }
}
