#container {
  width: 100%;
  max-width: 1440px;
  height: 100%;
  margin: 0 auto;
  padding: 128px 24px 48px 24px;

  @media (max-width: 600px) {
    padding-top: 84px;
    padding-left: 16px;
    padding-right: 16px;
  }
}
