#project {
  margin-top: 16px;
  margin-right: 5vw;
  margin-left: 5vw;
  @media (max-width: 1021px) {
    margin: 16px 2vw 0 2vw;
  }
  @media (max-width: 750px) {
    margin: 16px 1vw 0 1vw;
  }
  > .wrapper {
    padding: 2vh 5vw 0 5vw;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    row-gap: 24px;
    column-gap: 24px;

    @media (max-width: 1021px) {
      padding: 2vh 1vw 0 1vw;
    }
    @media (max-width: 750px) {
      padding: 2vh 0 0 0;
      grid-template-columns: 1fr;
    }

    #identification,
    #location {
      display: flex;
      flex-direction: column;
      justify-content: center;
      @media (max-width: 750px) {
        width: 100%;
      }
    }
    #identification {
      grid-column: 1;
      grid-row: 1;
    }
    #location {
      grid-column: 2;
      grid-row: 1;
      @media (max-width: 750px) {
        grid-column: 1;
        grid-row: 3;
      }
    }
    #chains {
      grid-column: 1/3;
      grid-row: 2;
      @media (max-width: 750px) {
        grid-column: 1;
        grid-row: 2;
      }
    }

    .box {
      flex: 1;
      padding: 2vh 1vw;
      @media (max-width: 750px) {
        width: 100%;
      }
    }
  }
}
