#chains {
  width: 100%;

  > .wrapper {
    background: #ffffff;
    border: 2px solid #dee1e3;
    border-radius: 8px;
    padding: 14px;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }

  .rowInChains {
    min-height: 109px;
    background: #ffffff;
    border: 2px solid #dee1e3;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow-x: auto;
    cursor: pointer;

    > .type {
      height: 105px;
      width: 90px;
      min-width: 90px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      row-gap: 8px;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
    }

    > .name {
      flex: 1;
      display: flex;
      align-items: center;

      > div {
        flex: 1;
        height: 105px;
        width: 183px;
        min-width: 183px;
        padding-left: 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-weight: 400;
        row-gap: 4px;
        font-size: 12px;
        line-height: 14px;
        color: #3c3b3b;
        > span {
          font-weight: 700;
          font-size: 16px;
          line-height: 19px;
          color: #241200;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    > .operationWrapper {
      width: 260px;
      min-width: 260px;
      max-width: 260px;
      position: relative;
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      > .operationSeparator {
        position: absolute;
        left: 0;
        top: 19px;
        right: 0;
        border-bottom: 2px dashed #00e697;
        > svg {
          position: absolute;
          left: calc(50% - 5px);
          right: 0;
          top: calc(50% - 7px);
        }
      }
      > .operation {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 4px;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #777777;
        > span {
          width: 100px;
          max-width: 100px;
          overflow: hidden;
          text-align: center;
          font-size: 14px;
          line-height: 17px;
          color: #000000;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        > .operationHideLeft {
          position: absolute;
          top: 0;
          left: 0;
          height: 38px;
          width: calc(50% - 19px);
          background-color: white;
        }
        > .operationHideRight {
          position: absolute;
          top: 0;
          right: 0;
          height: 38px;
          width: calc(50% - 19px);
          background-color: white;
        }
      }
    }

    > .qrCode {
      margin-left: 16px;
      margin-right: 10px;

      @media (max-width: 740px) {
        display: none;
      }
    }

    > .arrow {
      height: 105px;
      display: flex;
      align-items: center;
      padding-left: 14px;
      padding-right: 14px;
    }
  }

  .rowInChainsMobile {
    height: 170px;
    flex-wrap: wrap;
    align-items: normal;
    align-content: flex-start;

    > .type {
      height: 69px;
      width: 58px;
      // min-width: 58px;
      row-gap: 4px;
      > svg {
        height: 32px;
        width: 32px;
      }
    }

    > .name {
      height: 69px;
      > div {
        height: 46px;
        // min-width: 120px;
        border-left: 1px solid #6f7d81;
      }
    }

    > .operationWrapper {
      flex: 0 0 100%;
      min-width: 360px;
      // height: 98px;
      order: 2;
      margin: 8px auto 0 auto;
      align-self: center;
      @media (max-width: 500px) {
        min-width: 260px;
      }
    }

    > .arrow {
      height: 69px;
    }
  }
}
