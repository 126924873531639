#resource {
  margin: 16px 2vw 0 2vw;
  @media (max-width: 1021px) {
    margin: 16px 2vw 0 2vw;
  }
  @media (max-width: 750px) {
    margin: 16px 1vw 0 1vw;
  }

  > .wrapper {
    padding: 2vh 1vw 0 1vw;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: auto auto 1fr;
    row-gap: 24px;
    column-gap: 24px;

    #identification,
    #equipment,
    #location,
    #chainDetail {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    @media (max-width: 1021px) {
      padding: 2vh 1vw 0 1vw;
    }
    @media (max-width: 750px) {
      padding: 2vh 0 0 0;
      grid-template-columns: 1fr;
    }

    #identification {
      grid-column: 1;
      grid-row: 1;
    }
    #equipment {
      grid-column: 1;
      grid-row: 2;
    }
    #location {
      grid-column: 1;
      grid-row: 3;
      @media (max-width: 750px) {
        grid-column: 1;
        grid-row: 4;
      }
    }
    #chainDetail {
      grid-column: 2;
      grid-row: 1/4;
      height: 100%;
      .wrapper {
        height: 100%;
      }
      @media (max-width: 750px) {
        grid-column: 1;
        grid-row: 3;
      }
    }
    .box {
      width: 100%;
    }
  }
}
