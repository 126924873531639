.activityOperation {
  position: relative;
  width: 38px;
  height: 38px;
  border-radius: 6.62px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #241200;
}
