.insideBoxColumns {
  display: flex;
  flex-direction: row;
  column-gap: 24px;
  justify-content: space-between;
}

.boxColumns {
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 24px;
}
.insideBoxData {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #3c3b3b;
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  > a {
    color: #7a48ff;
    font-weight: 600;
    text-decoration: none;
    font-size: 12px;
  }
  > span {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #777777;
  }
}
