.box {
  background: #ffffff;
  border: 2px solid #dee1e3;
  border-radius: 8px;
  padding: 14px;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  overflow: hidden;

  .insideBoxColumns {
    display: flex;
    flex-direction: row;
    column-gap: 24px;
  }
}
